const nodeRedUrl = 'https://jouwwijkapp.nl/api/v1/logs';

export const sendLog = async (level, message, details = {}) => {
  // Don't attempt to send logs in development environment
  if (process.env.NODE_ENV === 'development') {
    console.log(`[${level}] ${message}`, details);
    return;
  }

  const log = {
    timestamp: new Date().toISOString(),
    level,
    message,
    details,
    environment: process.env.NODE_ENV,
    userAgent: navigator.userAgent
  };

  try {
    const response = await fetch(nodeRedUrl, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(log),
    });
    
    if (!response.ok) {
      // Fall back to console logging if the API call fails
      console.error(`[${level}] ${message}`, details);
    }
  } catch (error) {
    console.error(`[${level}] ${message}`, details);
  }
};

export const logError = (message, details) => sendLog('ERROR', message, details);
export const logWarning = (message, details) => sendLog('WARNING', message, details);