import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from './errorBoundary';
import App from './App';
import { logError, logWarning } from './logger';

console.log(navigator)

console.error = (function (originalConsoleError) {
  return function (...args) {
    console.log(args)
    const errorDetails = {
      url: window.location.href, // Add the current URL
      userAgent: {
        "agent" : navigator.userAgent, 
        "data" : navigator.userAgentData,
      },
      performance: window?.performance,
      stack: new Error().stack,
      details: args.slice(1),
    };
    logError(args[0], errorDetails);
    originalConsoleError.apply(console, args);
  };
})(console.error);

console.warn = (function (originalConsoleWarn) {
  return function (...args) {
    const warningDetails = {
      url: window.location.href, // Add the current URL
      userAgent: {
        "agent" : navigator.userAgent, 
        "data" : navigator.userAgentData,
      },
      performance: window?.performance,
      stack: new Error().stack,
      details: args.slice(1),
    };
    logWarning(args[0], warningDetails);
    originalConsoleWarn.apply(console, args);
  };
})(console.warn);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
